import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  outro: {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 0, 1, 1.5),
    marginTop: theme.spacing(1)
  }
}))
