import { Box, Dialog, Grid } from '@mui/material'
import { ReButton } from '../../../components'
import { useStyles as useModalStyles } from './style'
import * as React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import CICOProgress from '../cicoProgress'
import useCICOContext from '../../../hooks/useCICOContext'

const BootstrapDialogTitle = (props) => {
  const { onClose } = props
  return (
    <Box sx={{ background: 'white', height: '40px' }} display='flex'>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          color: (theme) => theme.palette.primary.main
        }}
      >
        <Close />
      </IconButton>
    </Box>
  )
}

export default function CicoItemModal({ open, handleClose, url, progress, isLastItem }) {
  const { moveNextStep, moveNextItem, isLastStep } = useCICOContext()
  const classes = useModalStyles()
  const { innerHeight: height } = window

  const title = React.useMemo(() => {
    if (isLastItem && isLastStep) return 'Finish'
    if (isLastItem) return 'Go to the next step'
    return 'Next Course'
  }, [isLastItem, isLastStep])

  const action = React.useCallback(() => {
    if (isLastItem && isLastStep) {
      handleClose()
    } else if (isLastItem) {
      moveNextStep()
      handleClose()
    } else {
      moveNextItem()
    }
  }, [isLastItem, isLastStep, moveNextItem, moveNextStep, handleClose])

  return (
    <div>
      <Dialog
        className={classes.dialog}
        PaperProps={{ style: { borderRadius: '15px', margin: 0, height: '97%', maxHeight: '97%' } }}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
        maxWidth='xl'
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose} progress={progress} />
        <DialogContent style={{ padding: '0px', overflowY: 'hidden', height: '100%' }}>
          <Grid container item xs={12} height='100%'>
            {url?.length && <iframe frameBorder='0' src={url} title='description' height={`${height - 125}px`} width='100%' />}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-between' width='100%' marginX={2} height='40px'>
            <CICOProgress loading={false} progress={progress} size='small' />
            <ReButton action={action} variant='contained' name={title} large margin='none' disabled={progress !== 100} />
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
}
