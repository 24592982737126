import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Grid } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEventContext } from '../../contexts/event'
import { FullScreenToolbar, Overlay } from '../../components'
import { userSlice } from '../../reducers'
import { CICOSteps, CICOStepsSkeletonList } from './cicoSteps'
import { CICOItems } from './cicoItems'
import CICOHeader from './header'
import CICOSubHeader from './subheader'
import { featureSelector } from '../../reducers/user/selectors'
import { Forbidden } from '../index'
import useCICOContext, { CICOContextProvider } from '../../hooks/useCICOContext'
import CICOOutro from './outro'

const CheckinCheckoutWrapper = () => {
  const params = useParams()
  const { getUser } = useEventContext()
  const user = useSelector(userSlice.selectors.selectUser)
  const features = useSelector(featureSelector)
  const userId = useMemo(() => user.clientId && user.ssoId ? `${user.clientId}_${user.ssoId}` : null, [user])
  const {
    getCICOInfo,
    CICOProgressWasUpdated,
    info,
    cicoSubType,
    setCicoSubType,
    selectedStep,
    loading,
  } = useCICOContext()

  // get cico info and progress on load
  useEventsOnViewLoad(() => {
    if (!getCICOInfo?.hasBeenTriggered) {
      getCICOInfo.trigger({ id: params.id })
    }
  }, [getCICOInfo])

  // register event listener
  useEffect(() => {
    if (userId && CICOProgressWasUpdated) {
      CICOProgressWasUpdated.trigger({ id: userId })
    }
  }, [userId, CICOProgressWasUpdated])

  // set fast or depth
  useEffect(() => {
    if (info && !info.hasFastTrack) {
      setCicoSubType('depth')
    }
  }, [info, setCicoSubType])

  const renderList = useMemo(() => {
    if (cicoSubType === 'depth' && !selectedStep) return <CICOSteps />
    return <CICOItems />
  }, [cicoSubType, selectedStep])

  if (getUser.isWorking) return (<>Loading...</>)
  if (!features.FUNDAMENTALS && params.id === 'fundamentals') <Forbidden />
  if (!features.CHECKIN_CHECKOUT && params.id === 'cico-main') <Forbidden />

  return (
    <Overlay>
      <Grid container alignItems='center' justifyContent='center'>
        <FullScreenToolbar navigateTo='../../' />
        <Grid container marginX={5} width='80%' maxWidth={1280}>
          <CICOHeader />
          <CICOSubHeader />
          <Grid container spacing={2}>
            {loading ? <CICOStepsSkeletonList /> : renderList}
          </Grid>
          <CICOOutro />
        </Grid>
      </Grid>
    </Overlay>
  )
}

const CheckinCheckout = () => {
  return (
    <CICOContextProvider>
      <CheckinCheckoutWrapper />
    </CICOContextProvider>
  )
}

export default CheckinCheckout
