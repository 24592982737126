import React, { useState } from 'react'
import {
  Divider, Typography, Paper, Box, FormControl, Table, TableContainer,
  TableHead, TableRow, TableCell, TableBody, TextField, Autocomplete, IconButton
} from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateMoment from '@mui/lab/AdapterMoment'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import moment from 'moment'
import { DeleteForever } from '@mui/icons-material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useStyles } from './styles'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { ReButton } from '../../../../components'

const PlanSelection = () => {
  const classes = useStyles()
  const [plans, setPlans] = useState([])
  const { control, getValues, setValue } = useFormContext()
  const { fields: createLicenses, append: addLicense, remove } = useFieldArray({
    control,
    name: 'licenses',
    rules: { required: true }
  })
  const getInfo = getValues()

  const selectedPlansPk = createLicenses.map(plan => plan.sk)
  const availablePlans = plans.filter(plan => !selectedPlansPk.includes(plan.pk))

  const filter = createFilterOptions()

  const [planListWasRequested] = useCorvusEventList([{
    eventName: 'Plans List Was Requested',
    onSuccess: (result) => {
      setPlans(result.plans)
    }
  }])

  useEventsOnViewLoad(() => {
    planListWasRequested.trigger()
  }, [planListWasRequested])

  const handlePlanSelection = (event, value) => {
    const selectedPlan = value
    addLicense({
      pk: getInfo.id,
      sk: selectedPlan.pk,
      name: selectedPlan.name,
      seats: 0,
      startDate: '',
      endDate: ''
    })
  }

  return (
    <div>
      <div>
        <Box display='flex' flexDirection='row'>
          <Box mr={2}>
            <Typography fontSize={10}>Organisation name</Typography>
            <Typography className={classes.organisationNameCheck}>{getInfo.name}</Typography>
          </Box>
          <Divider orientation='vertical' flexItem />
          <Box mr={2} ml={2}>
            <Typography fontSize={10}>ID</Typography>
            <Typography fontSize={16}>{getInfo.name.replace(/ /g, '-').toLowerCase()}</Typography>
          </Box>
          <Divider orientation='vertical' flexItem />
          <Box ml={2}>
            <Typography fontSize={10}>Admin</Typography>
            {getInfo.orgAdminEmail.map(email => (
              <Typography key={email.adminEmail} fontSize={16}>{email.adminEmail}</Typography>
            ))}
          </Box>
        </Box>
      </div>
      {createLicenses.length > 0 && (
        <Box mt={5}>
          <div className={classes.root}>
            <TableContainer sx={{ width: 906 }} component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  size: 'small'
                }}
                aria-label='plans'
              >
                <TableHead sx={{ backgroundColor: '#EDEEF1' }}>
                  <TableRow key={plans.sk}>
                    <TableCell className={classes.tableCellDef}>Plans</TableCell>
                    <TableCell className={classes.tableCellDef}>Seats</TableCell>
                    <TableCell className={classes.tableCellDef}>Start</TableCell>
                    <TableCell className={classes.tableCellDef}>End</TableCell>
                    <TableCell className={classes.tableCellDef}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {createLicenses.map((license, index) => (
                    <TableRow key={license.name}>
                      <TableCell scope='row'>{license.name}</TableCell>
                      <TableCell scope='row'>
                        <Controller
                          name={`licenses.${index}.seats`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <div>
                              <TextField
                                sx={{ width: 70 }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                placeholder='Seats'
                                {...field}
                                onChange={ev => {
                                  const parsedValue = parseInt(ev.target.value, 10)
                                  setValue(`licenses.${index}.seats`, !isNaN(parsedValue) ? parsedValue : 0)
                                }}
                              />
                            </div>
                          )}
                        />
                      </TableCell>
                      <TableCell scope='row'>
                        <Controller
                          name={`licenses.${index}.startDate`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateMoment}>
                              <div className={classes.datePickerWrapper}>
                                <DesktopDatePicker
                                  label='Start Date'
                                  inputFormat='DD/MM/YYYY'
                                  {...field}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </div>
                            </LocalizationProvider>
                          )}
                        />
                      </TableCell>
                      <TableCell scope='row'>
                        <Controller
                          name={`licenses.${index}.endDate`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateMoment}>
                              <div className={classes.datePickerWrapper}>
                                <DesktopDatePicker
                                  label='End Date'
                                  inputFormat='DD/MM/YYYY'
                                  {...field}
                                  minDate={moment()}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </div>
                            </LocalizationProvider>
                          )}
                        />
                      </TableCell>
                      <TableCell scope='row'>
                        <IconButton onClick={() => remove(index)}>
                          <DeleteForever color='#52575C' />
                        </IconButton>
                      </TableCell>
                      {/* <MoreOptionsCell row={client} onDeleteSuccess={handleDeleteSuccess}/> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      )}
      <FormControl sx={{ width: 600 }}>
        <Box mt={5} mb={1.2}>
          <Autocomplete
            loading={planListWasRequested.isWorking}
            onChange={handlePlanSelection}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.name)
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Add "${inputValue}"`,
                  addNew: true
                })
              }
              return filtered
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={availablePlans}
            getOptionLabel={(option) => {
              return option.name
            }}
            renderOption={(props, option) => option.addNew
              ? (
                <Box ml={1}>
                  <ReButton
                    name='Create new plan'
                    type='button'
                    bgColor='#FFF'
                    color='#465270'
                    btBorderWidth='2px'
                    btBorderColor='#465270'
                    href='/devsquad/dashboard/plans/new'
                    target='__blank'
                  />
                </Box>
              )
              : <li {...props}>{option.name}</li>}
            sx={{ width: 600 }}
            renderInput={(params) => (
              <TextField {...params} label='Select a Plan' />
            )}
          />
        </Box>
      </FormControl>
    </div>
  )
}

export default PlanSelection
