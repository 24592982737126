import React, { useCallback } from 'react'
import { Grid, Paper, Typography, Tooltip, Skeleton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useStyles } from './style'
import { ToolPathwayItem } from '../index'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'
import { useEventContext } from '../../contexts/event'

const ToolPathwayCard = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const user = useSelector(userSlice.selectors.selectUser)
  const { getUser } = useEventContext()
  const userRead = useSelector(userSlice.selectors.selectUserRead)
  const showing = useSelector(userSlice.selectors.selectShowToolpathOrQuestionnaire)
  const { getUserQuestionnairePathway, getUserToolpathway } = useEventContext()

  const getItemStatus = useCallback((id) => {
    if (userRead.find(i => i.sk === id.toString())) return 'read'
    else return 'pending'
  }, [userRead])

  if (getUser.isWorking || getUserQuestionnairePathway.isWorking || getUserToolpathway.isWorking) {
    return <Skeleton height={264} variant='rectangular' />
  }

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid id='tool-pathway-playlist' item xs={12}>
        <Grid container alignItems='center'>
          <Typography variant='h6' className={classes.toolpathwayTitle}>Tool Pathway</Typography>
          <Tooltip
            title={
              <div>
                <Typography mb={2} className={classes.infoIconWording}>
                  Tool Pathways provide personalised recommendations of tools from the Toolkit for you to complete.
                </Typography>
                <Typography mb={2} className={classes.infoIconWording}>
                  Once you complete your self-assessment, the Resilience Dynamic® Questionnaire or Indicator, you'll see a Resilience Level Tool Pathway which recommends tools for you based on your resilience level.
                </Typography>
                <Typography mb={2} className={classes.infoIconWording}>
                  If you have access to and are using the Resilience Tracker, you'll have an additional Tracking Pathway which recommends tools based on your tracking data from the previous week.                  </Typography>
                <Typography className={classes.infoIconWording}>
                  Click to toggle between your Resilience Level Pathway and Tracking Pathway.
                </Typography>
              </div>
            }
          >
            <InfoOutlinedIcon className={classes.popoverIcon} />
          </Tooltip>
        </Grid>
        <Grid container direction='column'>
          {(showing === 'toolpathway' ? user.toolpathway : user.questionnairePathway).slice(0, 3).map((c, i) =>
            <ToolPathwayItem
              key={c.id}
              item={c}
              selectContent={() => navigate('tool-pathway-content', { state: { id: c.id } })}
              itemStatusForUser={getItemStatus(c.id)}
              bottomTrail
            />
          )}
          <ToolPathwayItem
            item={{ title: { rendered: 'See all' }, jetpack_featured_media_url: '/icons/pathway-see-all-v2.svg' }}
            selectContent={() => navigate('tool-pathway')}
            itemStatusForUser='see-all'
            bottomTrail={false}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ToolPathwayCard
