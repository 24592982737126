import React from 'react'
import { Grid, Typography, Modal, Box } from '@mui/material'
import { useStyles } from './style'
import ReButton from '../re-button'

export default function TeamReportsModal({ openModal, teamReports, clearCommonStates, setOpenModal }) {
    const classes = useStyles()

    return (
        <Modal
            open={openModal}
            onClose={!openModal}
        >
            <Grid
                container
                direction='column'
                flexWrap='nowrap'
                rowSpacing={3}
                className={classes.modal}
            >
                <Grid item xs={12}>
                    <Typography variant='h3' className={classes.modalTitle}>
                        All done!
                    </Typography>
                    <img
                        src='https://static.resilienceengine.live/img/team-reports-modal-v2.svg'
                        alt='Additional User Settings Congratulations'
                    />
                </Grid>
                <Grid item xs={12} marginBottom={3} marginLeft={5}>
                    <Typography component='p' variant='body3'>
                        The report was successfully generated. You can now click the button below to download it.
                    </Typography>
                </Grid>
                <Box marginBottom={3} display='flex' gap={3}>
                    <ReButton
                        name='Download'
                        onClick={() => {
                            window.open(teamReports?.link)
                            clearCommonStates()
                        }}
                    />
                    <ReButton
                        name='Cancel'
                        onClick={() => {
                            setOpenModal(false)
                            clearCommonStates()
                        }}
                    />
                </Box>
            </Grid>
        </Modal>
    )
}
