import React from 'react'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import {
  Login, HomePage, Questionnaire, Reports, ResilienceEvaluation,
  OrganisationalDrivers, TipsPage, ToolPathwayContent, ToolPathway, CheckinCheckout,
  MostPopularContent, Cycle, ProfilePage, Cohorts, NewCohort, InviteRequest, AdditionalUserSettings, EditCohort, Teams,
  PlansHome, OrganisationsHome, PlanCreatePage, PlanEditPage, OrganisationCreatePage, OrgEditPage, SignIn, SignUp, ResetPassword, Confirmation, NotFound, RetroactiveTracker, ManageReportsPage
} from './pages'
import { PrimaryLayout } from './layouts'
import { PrivateRoute } from '@emerald-works/react-auth'
import NewTeam from './pages/teams/new'
import OrgRoute from './OrgRoute'
import ViewTeam from './pages/teams/view'
import UserManagementHome from './pages/user-management'
import EditUser from './pages/user-management/edit-user'
import ReportTemplateEditor from './pages/manage-reports/report-template-editor'

const ApplicationRoutes = () => {
  const params = useParams()

  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/' element={<Login />} />
      <Route path='sso/signin' element={<Navigate to={`/${params.tenantKey}/dashboard`} />} />
      <Route path='signin' element={<SignIn />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='signup' element={<SignUp />} />
      <Route path='confirmation' element={<Confirmation />} />
      <Route path='invite/:id' element={<InviteRequest />} />
      <PrivateRoute path='user-settings' element={<AdditionalUserSettings />} />

      <PrivateRoute path='dashboard' element={<PrimaryLayout />}>
        <PrivateRoute path='/' element={<HomePage />} />
        <PrivateRoute path='evaluation-results' element={<ResilienceEvaluation showResults type='resilience' />} />
        <PrivateRoute path='indicator-results' element={<ResilienceEvaluation showResults type='indicator' />} />
        <PrivateRoute path='tracker' element={<RetroactiveTracker />} />
        <PrivateRoute path='drivers' element={<OrganisationalDrivers />} />
        <PrivateRoute path='reports' element={<Reports />} />
        <PrivateRoute path='help' exact element={<TipsPage />} />
        <PrivateRoute path='help/:id' exact element={<TipsPage />} />
        <PrivateRoute path='tool-pathway' element={<ToolPathway />} />
        <PrivateRoute path='tool-pathway-content' element={<ToolPathwayContent />} />
        <PrivateRoute path='most-popular-content' element={<MostPopularContent />} />
        <PrivateRoute path='checkin-checkout/:id' element={<CheckinCheckout />} />
        <PrivateRoute path='checkin-checkout/:id/:step' element={<CheckinCheckout />} />
        <PrivateRoute path='cycle' element={<Cycle />} />
        <PrivateRoute path='profile' element={<ProfilePage />} />
        <PrivateRoute path='plans'>
          <PrivateRoute path='/' element={(<PlansHome />)} />
          <PrivateRoute path='new' element={(<PlanCreatePage />)} />
          <PrivateRoute path='edit/:planId' element={(<PlanEditPage />)} />
        </PrivateRoute>

        <PrivateRoute path='manage-reports' element={<ManageReportsPage />} />
        <PrivateRoute path='manage-reports/edit/:type/:category' element={<ReportTemplateEditor />} />

        <PrivateRoute path='organisations'>
          <PrivateRoute path='/' element={(<OrganisationsHome />)} />
          <PrivateRoute path='new' element={(<OrganisationCreatePage />)} />
          <PrivateRoute path='edit/:orgId' element={(<OrgEditPage />)} />
        </PrivateRoute>
        <OrgRoute path='cohorts'>
          <OrgRoute path='/' element={<Cohorts />} />
          <OrgRoute path='new' element={<NewCohort />} />
          <OrgRoute path='new/:cohortId' element={<NewCohort />} />
          <OrgRoute path='edit/:cohortId' element={<EditCohort />} />
        </OrgRoute>
        <OrgRoute path='teams'>
          <OrgRoute path='/' element={<Teams />} />
          <OrgRoute path='new' element={<NewTeam />} />
          <OrgRoute path=':teamId' element={<ViewTeam />} />
        </OrgRoute>
        <OrgRoute path='user-management'>
          <OrgRoute path='/' element={<UserManagementHome />} />
          <OrgRoute path=':userId' element={<EditUser />} />
        </OrgRoute>
      </PrivateRoute>
      {/* end on pages insde primary template */}
      <PrivateRoute
        path='survey'
        element={<Questionnaire context='survey' />}
      />
      <PrivateRoute
        path='indicator'
        element={<Questionnaire context='indicator' />}
      />
      <Route path='signout'>
        <Navigate to={`/${params.tenantKey}/`} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default ApplicationRoutes
