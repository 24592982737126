import { Box, Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import decamelize from 'decamelize'
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCorvusEvent } from '@emerald-works/react-event-bus-client'
import { useSelector } from "react-redux"

import { InformationalCard, Loading, ReButton } from "../../../components"
import { userSlice, clientSlice } from "../../../reducers"
import { RenderInput } from "../../additional-user-settings/step-one"
import { ArrowLeft } from "@mui/icons-material"
import useNavigation from "../../../helpers/useNavigation"
import { useAlert } from "../../../hooks"

const EditUser = () => {
  const [loading, setLoading] = useState(true)
  const { showSuccessAlert } = useAlert()
  const { userId } = useParams()
  const navigate = useNavigation()
  const clientConfig = useSelector(clientSlice.selectors.selectClientInfo)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const { control, getValues, formState: { errors }, trigger, setValue } = useForm()

  const getUserInfo = useCorvusEvent({
    eventName: 'getUserInfo', onSuccess: (data) => {
      setValue('name', data.name)
      setValue('email', data.email)
      setValue('systemRole', data.systemRole)
      if (data.personalSettings) {
        Object.entries(data.personalSettings).forEach(([k, v]) => {
          setValue(k, v)
        })
      }
      setLoading(false);
    }
  })

  const sendUserInfo = useCorvusEvent({
    eventName: 'Specific User Personal Settings Was Sent',
    onSuccess: () => {
      showSuccessAlert('User saved', 'User was saved successfully')
    }
  })

  const save = async () => {
    await trigger()
    if (Object.keys(errors).length) {
      const [missingField, info] = Object.entries(errors)[0]
      if (info.message.length) alert(info.message)
      else alert(`The field ${decamelize(missingField).replace(/_/g, ' ')} is required`)
      return
    }
    const values = getValues()
    sendUserInfo.trigger({ tenantKey: selectedView.id, ssoId: userId, ...values })
  }

  const getFields = useCallback((type) => {
    if (!clientConfig?.demographicFields) return null
    return clientConfig.demographicFields
      .filter(f => f.fieldCategory === type)
      .map(f => <RenderInput key={f.fieldKey} field={f} control={control} errors={errors} />)
  }, [clientConfig, control, errors])
  const personalFields = useMemo(() => getFields('personal'), [getFields])
  const occupationFields = useMemo(() => getFields('occupation'), [getFields])

  useEffect(() => {
    getUserInfo.trigger({ tenantKey: selectedView.id, ssoId: userId })
  }, [getUserInfo, selectedView, userId])

  if (loading) return <Loading message='Loading user...' />;
  return (
    <Box>
      <Button color='secondary' size='small' startIcon={<ArrowLeft />} onClick={() => navigate('/dashboard/user-management')}>Back to list</Button>
      <Box width='60%' display='flex' flexDirection='column' gap={2} mt={2}>
        <InformationalCard accState={true} title='Profile Information'>
          <Box display='flex' flexDirection='column' gap={2}>
            <RenderInput field={{ fieldType: 'input', fieldName: 'Name', fieldKey: 'name' }} control={control} errors={errors} />
            <RenderInput disabled field={{ fieldType: 'input', fieldName: 'Email', fieldKey: 'email' }} control={control} errors={errors} />
          </Box>
        </InformationalCard>
        <InformationalCard accState={true} title='Personal Information'>
          <Box display='flex' flexDirection='column' gap={2}>
            {personalFields}
          </Box>
        </InformationalCard>
        <InformationalCard accState={true} title='Occupation Information'>
          <Box display='flex' flexDirection='column' gap={2}>
            {occupationFields}
          </Box>
        </InformationalCard>
        {clientConfig?.hierarchyField && !!Object.keys(clientConfig?.hierarchyField).length &&
          <InformationalCard accState={true} title='User Role'>
            <RenderInput field={{
              fieldType: 'select',
              fieldName: clientConfig?.hierarchyField.fieldName,
              fieldKey: 'hierarchyField',
              fieldOptions: clientConfig.hierarchyField.fieldOptions
            }}
              control={control} errors={errors} />
          </InformationalCard>
        }
        <InformationalCard accState={true} title='System Information'>
          <Box display='flex' flexDirection='column' gap={2}>
            <RenderInput field={{
              fieldType: 'select',
              fieldName: 'System role',
              fieldKey: 'systemRole',
              fieldOptions: [{ key: 'user', name: 'User' }, { key: 'orgadmin', name: 'Org admin' }, { key: 'superadmin', name: 'Super admin' }]
            }}
              control={control} errors={errors} />
          </Box>
        </InformationalCard>
        <Box display='flex' gap={2}>
          <ReButton name='Save' btBorderWidth='2px' btBorderColor='#303155' action={save} loading={sendUserInfo.isWorking} />
          <ReButton
            disabled={sendUserInfo.isWorking}
            name='Cancel'
            type='button'
            bgColor='#FFF'
            color='#465270'
            btBorderWidth='2px'
            btBorderColor='#303155'
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EditUser
