import React from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import { AddCircle } from "@mui/icons-material";
import { FieldForm } from "./field-form";
import { HierarchyField } from "./hierarchy-field";

const OrgSpecifics = () => {
  const { control, watch } = useFormContext([]);

  const { append: addPersonalField, remove: removePersonalField } =
    useFieldArray({
      control,
      name: "personalFields",
    });
  const { append: addOccupationField, remove: removeOccupationField } =
    useFieldArray({
      control,
      name: "occupationFields",
    });
  const personalFields = watch('personalFields');
  const occupationFields = watch('occupationFields');

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
          Personal Information
        </Typography>
        {personalFields?.map((_v, index) => (
          <FieldForm
            key={_v.id}
            index={index}
            baseName="personalFields"
            removeFn={removePersonalField}
          />
        ))}
        <Box mb={3}>
          <IconButton color="secondary" onClick={() => addPersonalField({ id: uuidv4(), label: "", type: "input", aggregatable: true, options: [{ value: '' }] })}>
            <AddCircle fontSize="small" />
            <Typography fontSize={14} fontWeight="bold">
              Add another field
            </Typography>
          </IconButton>
        </Box>
        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
          Occupation Information
        </Typography>
        {occupationFields?.map((_v, index) => (
          <FieldForm
            key={_v.id}
            index={index}
            baseName="occupationFields"
            removeFn={removeOccupationField}
          />
        ))}
        <Box mb={3}>
          <IconButton color="secondary" onClick={() => addOccupationField({ id: uuidv4(), label: "", type: "input", aggregatable: true, options: [{ value: '' }] })}>
            <AddCircle fontSize="small" />
            <Typography fontSize={14} fontWeight="bold">
              Add another field
            </Typography>
          </IconButton>
        </Box>
        <Typography variant="h6" fontWeight="bold" marginBottom={2}>
          Hierarchy information
        </Typography>
        <HierarchyField />
      </Box>
    </>
  );
};



export default OrgSpecifics;
