import React, { useMemo, useState } from "react";
import {
  IconButton,
  TextField,
  Box,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useStyles } from "./styles";
import { Controller, useFormContext } from "react-hook-form";
import { RemoveCircle } from "@mui/icons-material";
import { ReButton } from "../../../../components";
import { SelectOptionModal } from "./select-option-modal";

const fieldTypes = [
  { label: "Input", value: "input" },
  { label: "Select", value: "select" },
];

export const FieldForm = ({ index, baseName, removeFn }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext([]);
  const getInfo = watch();

  const isSelect = useMemo(
    () => getInfo[baseName][index].type === "select",
    [baseName, getInfo, index]
  );

  return (
    <div>
      <SelectOptionModal open={open} setOpen={setOpen} baseName={baseName} index={index} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        gap={2}
        width={750}
        mb={2}
      >
        <Controller
          name={`${baseName}.${index}.label`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              className={classes.input}
              {...field}
              label="Field label"
              placeholder="Field label"
              error={!!errors[baseName]}
            />
          )}
        />
        <Controller
          name={`${baseName}.${index}.type`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              select
              className={classes.input}
              {...field}
              label="Field type"
              placeholder="Field type"
              error={!!errors[baseName]}
            >
              {fieldTypes.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {isSelect ? (
          <Box display="flex" width={145}>
            <ReButton
              action={() => setOpen(true)}
              name="Add options"
              bgColor="#FFF"
              color="#465270"
              btBorderWidth="2px"
              btBorderColor="#465270"
            />
          </Box>
        ) : null}
        <Controller
          name={`${baseName}.${index}.aggregatable`}
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox defaultChecked />} label="Aggregatable" {...field} checked={field.value} />
          )}
        />
        <Box mt={1}>
          <IconButton
            sx={{ color: "#CCCCCC" }}
            onClick={() => removeFn(index)}
          >
            <RemoveCircle />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};