import React, { useCallback } from 'react'
import { AppBar, Toolbar, Grid, Typography, ButtonBase, IconButton } from '@mui/material'
import { userSlice } from '../../reducers'
import { useStyles } from './style'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { HelpOutline } from '@mui/icons-material'
import { useLocation, useParams } from 'react-router-dom'
import { useOnboarding } from '../../hooks'

const FullScreenToolbar = ({ navigateTo = '../' }) => {
  const classes = useStyles()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { openOnFeature } = useOnboarding()

  const shouldHide = (
    !(location.pathname === `/${params.tenantKey}/dashboard/most-popular-content` ||
    location.pathname === `/${params.tenantKey}/dashboard/tool-pathway` ||
    location.pathname === `/${params.tenantKey}/dashboard/tool-pathway-content`)
  )

  const onHelpPress = useCallback(() => {
    const pathname = location.pathname.split('/').pop()
    openOnFeature(pathname)
  }, [location.pathname, openOnFeature])

  return (
    <AppBar
      position='relative'
      className={classes.appToolbar}
      style={{ minHeight: '88px' }}
    >
      <Toolbar>
        <Grid container alignItems='center'>
          <Grid item xs={12}>
            <Grid container alignItems='center' justifyContent='space-between'>
              {selectedView && selectedView.type === 'org' ? (
                <Typography className={classes.header} variant='h5'> Resilience Dynamic Dashboard®</Typography>
              ) : (
                <Typography className={classes.header} variant='h5'>
                  Resilience Dynamic Dashboard®<span className={classes.invisible}>{process.env.REACT_APP_BUILD_ID}</span>
                </Typography>
              )}
              <Grid>
                {shouldHide && <IconButton color='primary' onClick={onHelpPress}><HelpOutline /></IconButton>}
                <ButtonBase onClick={() => navigate(navigateTo)} className={classes.backButton}>
                  <Typography variant='subtitle2'>&lt; RETURN TO DASHBOARD</Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default FullScreenToolbar
