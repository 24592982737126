import { Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { progressReducer } from '../../../helpers/utils'
import useCICOContext from '../../../hooks/useCICOContext'
import CICOProgress from '../cicoProgress'
import { useStyles } from './style'

const CICOSubHeader = () => {
  const classes = useStyles()
  const { cicoSubType, selectedStep, contents, loading } = useCICOContext()

  const title = useMemo(() => {
    if (selectedStep) {
      return `Step ${selectedStep.stepIndex}`
    }
    if (cicoSubType === 'fast') {
      return 'Fast Track'
    }
    if (cicoSubType === 'caseStudy') {
      return 'Case Studies'
    }
    return ''
  }, [selectedStep, cicoSubType])

  const description = useMemo(() => {
    if (selectedStep) {
      return selectedStep.title
    }
    if (cicoSubType === 'fast') {
      return ''
    }
    return ''
  }, [selectedStep, cicoSubType])

  const subHeaderProgress = useMemo(() => {
    if (selectedStep) {
      const stepContents = contents.filter(c => c.stepId === selectedStep.stepId)
      const sum = stepContents.reduce(progressReducer, 0)
      const percent = sum / (stepContents.filter(c => !c.shouldIgnoreOnPercentage).length * 100)
      return Math.round(percent * 100)
    }
    if (cicoSubType === 'fast') {
      const fastTrackContents = contents.filter(c => c.fastTrackOrder !== 0)
      const total = fastTrackContents.reduce(progressReducer, 0)
      const percent = total / (fastTrackContents.filter(c => !c.shouldIgnoreOnPercentage).length * 100)
      return Math.round(percent * 100)
    }
    if (cicoSubType === 'caseStudy') {
      const caseStudyContents = contents.filter(c => c.caseStudyOrder !== 0)
      const total = caseStudyContents.reduce(progressReducer, 0)
      const percent = total / (caseStudyContents.filter(c => !c.shouldIgnoreOnPercentage).length * 100)
      return Math.round(percent * 100)
    }
    return 0
  }, [selectedStep, cicoSubType, contents])

  if ((cicoSubType === 'depth' && selectedStep === null) || loading) {
    return null
  }

  return (
    <Grid container marginBottom={3} marginTop={4}>
      <Grid display='flex' width='100%' justifyContent='space-between'>
        <Grid display='flex' alignItems='flex-start' flexDirection='column' gap={1}>
          <Typography variant='h4' color='primary'>{title}</Typography>
          <Typography variant='h5' color='secondary'>{description}</Typography>
        </Grid>
        <Grid display='flex' alignItems='center' gap={2}>
          <Grid display='flex' alignItems='center' gap={2}>
            <img src='/img/chart.svg' alt='chart' />
            <CICOProgress loading={false} size='medium' progress={subHeaderProgress || 0} />
          </Grid>
        </Grid>
      </Grid>
      {cicoSubType === 'fast' &&
        <Grid className={classes.longDescription}>
          <Typography variant='body1'>This is the Checkin Checkout Fast Track learning journey where you'll get the essentials you need before attending the Live Practise Sessions - in less time. However, we wouldn't want you to miss out on the CICO Case Studies. This is a series of conversations where a team are getting to grips with using the CICO structure and process. This collection is optionally available for you on the last tile.</Typography>
        </Grid>}
        {cicoSubType === 'caseStudy' &&
        <Grid className={classes.longDescription}>
          <Typography variant='body1'>This section contains each of the six case studies used throughout the in depth CICO guide. The case studies are a series of conversations where a team are getting to grips with using the CICO structure and process.</Typography>
        </Grid>}
      {selectedStep?.longDescription &&
        <Grid className={classes.longDescription}>
          <Typography variant='body1'>
            <span dangerouslySetInnerHTML={{ __html: selectedStep.longDescription }} />
          </Typography>
        </Grid>}
    </Grid>
  )
}
export default CICOSubHeader
