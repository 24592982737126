import React, { useState } from 'react'
import { Box, Collapse, Divider, List, Typography } from '@mui/material'
import OrgMenuItem from './org-menu-item'
import { useStyles } from './style'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const ReOrgMenu = ({ menu }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true);

  return (
    <Box paddingLeft={2} paddingTop={1} bgcolor='#F6F8FB'>
      <Box
        onClick={() => setOpen(s => !s)}
        style={{ cursor: 'pointer' }}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        paddingRight={2}
        >
        <Typography
          variant='caption'
          color='secondary.dark'
          fontWeight='bold'
          fontSize={13}
        >
          Organisational Settings
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Box paddingTop={1}><Divider /></Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component='nav' className={classes.appMenu}>
          {menu.map((item, index) => item.type === 'item' ? (
            <OrgMenuItem {...item} key={index} />
          ) : <Box key={index} paddingLeft={2} paddingY={1}><Divider /></Box>
          )}
        </List>
      </Collapse>
    </Box>
  )
}

export default ReOrgMenu
