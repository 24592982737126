import React, { useEffect, useRef, useState } from 'react';
import { useCorvusEventList } from '@emerald-works/react-event-bus-client';
import { Editor } from '@tinymce/tinymce-react';
import ReButton from '../re-button';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAlert } from '../../hooks';

export default function ReportEditor({ type, category }) {
  const alert = useAlert()
  const editorRef = useRef(null);
  const [content, setContent] = useState('')
  const [getReportTemplate, updateReportTemplate] = useCorvusEventList([

    { eventName: 'Report Template Was Requested', onSuccess: (data) => setContent(data.content) },
    {
      eventName: 'Report Template Was Updated',
      onSuccess: () => alert.showSuccessAlert("Saved", "Content was saved successfully"),
      onError: () => alert.showErrorAlert("Error", "Something went wrong"),
    }
  ])

  useEffect(() => {
    getReportTemplate.trigger({ type, category })
  }, [type, category, getReportTemplate])

  const save = () => {
    if (editorRef.current) {
      const currentContent = editorRef.current.getContent()
      console.log(currentContent);
      updateReportTemplate.trigger({ type, category, content: currentContent })
    }
  };

  if (getReportTemplate.isWorking) {
    return (
      <Box display='flex' alignItems='center' gap={1}>
        <CircularProgress />
        <Typography>Loading...</Typography>
      </Box>
    )
  }

  return (
    <>
      <Box mb={1}>
        <Editor
          apiKey='90xv1k112ldhcxxleglg1y1w9gzpzo38q8p8xfhjq7j5ptqm'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={content}
          init={initConfig(type)}
        />
      </Box>
      <ReButton name='Save' action={save} loading={updateReportTemplate.isWorking} />
    </>
  );
}

const initConfig = (type = 'questionnaire') => ({
  selector: 'textarea#open-source-plugins',
  plugins: 'code print preview paste importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
  imagetools_cors_hosts: ['picsum.photos'],
  menubar: 'file edit view insert format tools table help',
  toolbar: 'undo redo | template bold italic underline strikethrough | fontfamily fontsize | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | insertfile image link anchor | ltr rtl',
  toolbar_sticky: true,
  autosave_ask_before_unload: true,
  autosave_interval: '30s',
  autosave_prefix: '{path}{query}-{id}-',
  autosave_restore_when_empty: false,
  autosave_retention: '2m',
  image_advtab: true,
  importcss_append: true,
  file_picker_callback: function (callback, value, meta) {
    /* Provide file and text for the link dialog */
    if (meta.filetype === 'file') {
      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
    }

    /* Provide image and alt text for the image dialog */
    if (meta.filetype === 'image') {
      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
    }

    /* Provide alternative source and posted for the media dialog */
    if (meta.filetype === 'media') {
      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
    }
  },
  templates: [
    // sections
    type !== 'indicator-unauthenticated' && { title: 'SECTION - Toolkit only', description: 'Creates a section only available when toolkit is enabled', content: '<div class="toolkit-only">content here (background color will be removed on final version)<br>...content...<br>...content...</div>' },
    !type.includes('indicator') && { title: 'SECTION - Org drivers enabled', description: 'Creates a section only available when Org drivers is enabled', content: '<div class="org-drivers-only">content here (background color will be removed on final version)<br>...content...<br>...content...</div>' },
    !type.includes('indicator') && { title: 'SECTION - Org drivers disabled', description: 'Creates a section only available when Org drivers is disabled', content: '<div class="org-drivers-disabled">content here (background color will be removed on final version)<br>...content...<br>...content...</div>' },
    type === 'team-questionnaire' && { title: 'SECTION - is both questionnaire and indicator', description: 'Creates a section only available when report is questionnaire and indicator', content: '<div class="is-both-only">content here (background color will be removed on final version)<br>...content...<br>...content...</div>' },

    //templates
    { title: 'Score', description: '', content: '{{ score }}' },
    !type.includes('team') && { title: 'User name', description: '', content: '{{ user.name }}' },
    !type.includes('team') && { title: 'High score', description: '', content: '{{ high_score }}' },
    !type.includes('team') && { title: 'Low score', description: '', content: '{{ low_score }}' },
    !type.includes('team') && { title: 'High score level', description: '', content: '{{ high_score_level }}' },
    !type.includes('team') && { title: 'Low score level', description: '', content: '{{ low_score_level }}' },
    !type.includes('team') && { title: 'Secure areas', description: '', content: '{{ secure_value }}' },
    !type.includes('team') && { title: 'Neutral areas', description: '', content: '{{ neutral_value }}' },
    !type.includes('team') && { title: 'Watch areas', description: '', content: '{{ watch_value }}' },

    type.includes('team') && { title: 'Date', description: 'When the report was generated', content: '{{ date }}' },
    type.includes('team') && { title: 'Team name', description: '', content: '{{ team.name }}' },
    type.includes('team') && { title: 'Number of Participants', description: '', content: '{{ nParticipants }}' },
    type.includes('team') && { title: 'Number of Responses', description: '', content: '{{ nResponses }}' },
    type === 'team-questionnaire' && { title: 'Team Scores', description: '', content: '{{  teamScores }}' },
    type.includes('team') && { title: 'Team Lowest level', description: '', content: '{{  team_lowest_level }}' },
    type.includes('team') && { title: 'Average High Score', description: '', content: '{{ average_high_score }}' },
    type.includes('team') && { title: 'Average Low Score', description: '', content: '{{ average_low_score }}' },
    type.includes('team') && { title: 'Most Common Level', description: '', content: '{{ mostCommonLevel }}' },
    type.includes('team') && { title: 'Lowest and Highest Scores', description: '', content: '{{ lowestAndHighestScores }}' },
    type.includes('team') && { title: 'Secure areas', description: '', content: '{{ secure }}' },
    type.includes('team') && { title: 'Neutral areas', description: '', content: '{{ neutral }}' },
    type.includes('team') && { title: 'Watch areas', description: '', content: '{{ watch }}' },

    { title: 'Org drivers psychological', description: '', content: '{{  org_driver_psychological }}' },
    { title: 'Org drivers workload', description: '', content: '{{ org_driver_workload }}' },
    { title: 'Org drivers trust', description: '', content: '{{  org_driver_trust }}' },
    { title: 'Org drivers engagement', description: '', content: '{{  org_driver_engagement }}' },
  ].filter(Boolean),
  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
  height: '700px',
  image_caption: true,
  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  noneditable_noneditable_class: 'mceNonEditable',
  toolbar_mode: 'sliding',
  contextmenu: 'link image imagetools table',
  skin: 'oxide',
  content_css: (() => {
    switch (type) {
      case 'questionnaire': return '/report-css/questionnaire.css'
      case 'indicator': return '/report-css/indicator.css'
      case 'indicator-unauthenticated': return '/report-css/indicator.css'
      case 'team-questionnaire': return '/report-css/team-report.css'
      case 'team-indicator': return '/report-css/team-report.css'
      default: return '/report-css/indicator.css'
    }
  })(),
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  font_size_formats: "0.5rem 0.6rem 0.7rem 0.8rem 0.9rem 1rem 1.2rem 1.4rem 1.6rem 1.8rem 2rem"
});
