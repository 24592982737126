import React, { useEffect } from 'react'
import { ReButton } from '../../components'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import ReportTemplateList from './report-template-list'
import { Add } from '@mui/icons-material'
import { useStyles } from './style'
import { useCorvusEvent } from '@emerald-works/react-event-bus-client'
import { getReportTemplateList } from '../../reducers/reportTemplate/eventBus'


const templateOptions = [
  { value: 'questionnaire', label: 'Questionnaire' },
  { value: 'indicator', label: 'Indicator' },
  { value: 'indicator-unauthenticated', label: 'Indicator (unauthenticated)' },
  { value: 'team-questionnaire', label: 'Team Report (Questionnaire)' },
  { value: 'team-indicator', label: 'Team Report (Indicator)' },
  { value: 'component', label: 'Components' },
]

const ManageReportsPage = () => {
  const getReportTemplateListBurst = useCorvusEvent(getReportTemplateList)
  const styles = useStyles()

  const [currentType, setCurrentType] = React.useState('indicator');

  const handleChange = (event, newValue) => {
    setCurrentType(newValue);
  };

  useEffect(() => {
    getReportTemplateListBurst.trigger({ type: currentType })
  }, [getReportTemplateListBurst, currentType])

  return (
    <Box p={3}>
      <Box className="header" display='flex' alignItems='center' gap={3}>
        <Typography variant='h3'>Manage PDF Reports</Typography>
        <ReButton name="Add New" icon={<Add />} rounded />
      </Box>
      <Box className={styles.main}>
        <Box className="report-selector">
          <Tabs value={currentType} onChange={handleChange} variant='scrollable' scrollButtons={true} allowScrollButtonsMobile>
            {templateOptions.map(to => (
              <Tab label={to.label} value={to.value} />
            ))}
          </Tabs>
        </Box>
        <Box className="report-template-list">
          <ReportTemplateList currentType={currentType} />
        </Box>
      </Box>
    </Box>
  )
}

export default ManageReportsPage
