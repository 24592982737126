import React, { useState } from 'react'
import { ReButton, ReportEditor } from '../../../components'
import { useParams } from 'react-router-dom'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useStyles } from './style'
import { ArrowLeft } from '@mui/icons-material'
import useNavigation from '../../../helpers/useNavigation'
import { useCorvusEvent } from '@emerald-works/react-event-bus-client'

const adjustText = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).replace(/_/g, ' ')
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ReportTemplateEditor = () => {
  const { type, category } = useParams()
  const navigate = useNavigation()
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(null)

  const getLivePreviewBurst = useCorvusEvent({ eventName: 'Report Template Preview Was Generated', onSuccess: res => {
    setPreviewUrl(res)
    setOpen(true)
  } })

  const getLivePreview = () => getLivePreviewBurst.trigger({ type, category })

  return (
    <Box p={3} className="main">
      <Box className="header" mb={1}>
        <Button color='secondary' size='small' startIcon={<ArrowLeft />} onClick={() => navigate('/dashboard/manage-reports')}>Back to list</Button>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h3'>
          {adjustText(type)} - {adjustText(category)}
        </Typography>
        <ReButton name='Live Preview' action={getLivePreview} loading={getLivePreviewBurst.isWorking}/>
      </Box>
      <Box className={styles.editor}>
        <ReportEditor type={type} category={category} />
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Link ready
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <a href={previewUrl}>Click here to download the pdf preview</a>
          </Typography>
        </Box>
      </Modal>
    </Box>
  )
}

export default ReportTemplateEditor
