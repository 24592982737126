import { Grid, Typography } from '@mui/material'
import useCICOContext from '../../../hooks/useCICOContext'
import { useStyles } from './style'

const CICOOutro = () => {
  const classes = useStyles()
  const { cicoSubType, selectedStep, loading } = useCICOContext()

  if ((cicoSubType === 'depth' && selectedStep === null) || loading) {
    return null
  }

  return (
    <Grid container marginBottom={3} marginTop={4}>
      {cicoSubType === 'fast' &&
        <Grid className={classes.outro}>
          <Typography variant='body1'>Once you have started your Checkin Checkout learning journey, you will be signed up to a live practice session based on your region. Keep an eye on your emails for your Google Meet invite.</Typography>
        </Grid>}
      {selectedStep?.outro &&
        <Grid className={classes.outro}>
          <Typography variant='body1'>
            <span dangerouslySetInnerHTML={{ __html: selectedStep.outro }} />
          </Typography>
        </Grid>}
    </Grid>
  )
}
export default CICOOutro
