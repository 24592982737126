import React, { useMemo } from 'react'
import { InformationalCard } from '..'
import { Skeleton } from '@mui/material'
import { useStyles } from './style'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { clientConfigSlice, userSlice } from '../../reducers'
import { useEventContext } from '../../contexts/event'
import { useSelector } from 'react-redux'
import { ProfileInfoField } from '../profile-info/profile-info-field'

const OccupationInfoCard = ({ currentPersonalSettings, setCurrentPersonalSettings, accState }) => {
  const classes = useStyles()
  const { getUser } = useEventContext()
  const clientConfig = useSelector(clientConfigSlice.selectors.selectClientConfig)

  const [sendPersonalSettings] = useCorvusEventList([userSlice.eventBus.saveUserPersonalSettings])

  const isLoading = getUser.isWorking
  const handleChange = (e, shouldSend) => {
    const { name, value } = e.target
    const newUserSettings = { ...currentPersonalSettings }
    newUserSettings[name] = value
    setCurrentPersonalSettings(newUserSettings)
    if (shouldSend) sendPersonalSettings.trigger(newUserSettings)
  }
  const handleSave = () => sendPersonalSettings.trigger(currentPersonalSettings)

  const occupationFields = useMemo(() => clientConfig.demographicFields.filter(f => f.fieldCategory === 'occupation'), [clientConfig])

  return (
    <div className={classes.root}>
      <InformationalCard title='Organisation Information' accState={accState}>
        {isLoading
          ? (
            <span>
              <Skeleton style={{ marginBottom: '-20px', marginTop: '-20px' }} variant='text' animation='wave' height='100px' width='100%' />
              <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='100px' width='100%' />
            </span>
          )
          : (
            <div className={classes.cardsContainer}>
              {occupationFields.map((field) => (
                <ProfileInfoField
                  field={field}
                  canEdit
                  key={field.fieldKey}
                  value={currentPersonalSettings?.[field.fieldKey] || ''}
                  handleChange={handleChange}
                  handleSave={handleSave}
                />
              ))}
              {clientConfig.hierarchyField && !!Object.keys(clientConfig.hierarchyField).length && (
                <ProfileInfoField
                  canEdit={false}
                  field={{ ...clientConfig.hierarchyField, fieldKey: 'hierarchyField', fieldType: 'select' }}
                  value={currentPersonalSettings?.hierarchyField || ''}
                  handleChange={handleChange}
                  handleSave={handleSave}
                />
              )}
            </div>
          )}
      </InformationalCard>
    </div>
  )
}

export default OccupationInfoCard
