import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { Close } from '@mui/icons-material'
import { CircularProgress, Dialog, DialogContent, Grid, IconButton, Slider, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CicoItemModal from '../cico-item-modal'
import { usePromiseEvent } from '../../../hooks'
import { Loading, ReButton } from '../../../components'
import { cicoSlice, userSlice } from '../../../reducers'
import { useStyles } from '../style'
import { useStyles as useModalStyles } from './style'
import useCICOContext from '../../../hooks/useCICOContext'

const CICOItems = () => {
  const [loading, setLoading] = useState(false)
  const { items } = useCICOContext()
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '10%',
            width: '-webkit-fill-available'
          }
        }}
        open={loading}
      >
        <Loading />
      </Dialog>
      {items?.map((item, idx) =>
        <CICOItem
          item={item}
          idx={idx}
          key={idx}
          setLoading={setLoading}
          loading={loading}
          isLastItem={idx === items.length - 1}
        />
      )}
    </>
  )
}

const CICOItem = ({ item, idx, setLoading, loading, isLastItem }) => {
  const { selectedItem, setSelectedItem, setCicoSubType } = useCICOContext()
  const classes = useStyles({ itemSource: item.contentSource })
  const [cicoToken, setCicoToken] = useState('')
  const [cicoUrl, setCicoUrl] = useState('')
  const user = useSelector(userSlice.selectors.selectUser)
  const userId = useMemo(() => user.clientId && user.ssoId ? `${user.clientId}_${user.ssoId}` : null, [user.clientId, user.ssoId])
  const generateCICOToken = usePromiseEvent('CICO Token Generate Was Requested')
  const [changeCICOProgress] = useCorvusEventList([cicoSlice.eventBus.cicoProgressWasChanged])

  const onClick = () => {
    setSelectedItem(idx)
    console.log(item)
    onLoad()
  }

  const onLoad = useCallback(async () => {
    setLoading(true)
    if (item.itemType === 'site') {
      let result = ''
      if (!cicoToken?.length) {
        console.log('generating cico token!')
        const token = await generateCICOToken.promise({ cicoId: item.pk, userId, stepId: item.stepId, contentId: item.contentId })
        setCicoToken(token)
        result = `${item.url}?cicoToken=${token}`
      } else {
        result = `${item.url}?cicoToken=${cicoToken}`
      }
      if (item.state) result += `&state=${item.state}`
      setCicoUrl(result)
    }
    if (item.itemType === 'file') {
      setCicoUrl(item.url)
      changeCICOProgress.trigger({ cicoId: item.pk, userId, stepId: item.stepId, contentId: item.contentId, progress: 100 })
    }
    if (item.itemType === 'changeSubType') {
      setCicoSubType(item.goTo)
      changeCICOProgress.trigger({ cicoId: item.pk, userId, stepId: item.stepId, contentId: item.contentId, progress: 100 })
    }
    setLoading(false)
  }, [generateCICOToken, userId, changeCICOProgress, setLoading, item, cicoToken, setCicoSubType])

  useEffect(() => {
    if (selectedItem === idx && !cicoUrl.length && !loading) onLoad()
  }, [selectedItem, idx, onLoad, cicoUrl, loading])

  useEffect(() => () => {
    if (selectedItem !== idx && cicoUrl.length) {
      setCicoUrl('')
      setCicoToken('')
    }
  }, [selectedItem, idx, cicoUrl])

  return (
    <Grid item xs={12} sm={6} md={4} key={item.id}>
      <QuestionnaireModal item={item} userId={userId} changeCICOProgress={changeCICOProgress} open={selectedItem === idx && item.itemType === 'questionnaire' && !loading} handleClose={() => setSelectedItem(-1)} />
      <CicoItemModal
        url={cicoUrl}
        progress={item.progress}
        changeCICOProgress={changeCICOProgress}
        open={selectedItem === idx && ['site', 'file'].includes(item.itemType) && !loading}
        handleClose={() => setSelectedItem(-1)}
        isLastItem={isLastItem}
      />
      <Grid className={classes.cicoItem} onClick={onClick}>
        <Grid display='flex' alignItems='flex-start'>
          <div className={classes.cicoItemNumber}>
            <Typography component='span'>{idx + 1}</Typography>
          </div>
          <Grid>
            <Typography variant='h4' color='secondary'>{item.title}</Typography>
            <Typography variant='caption' color='text.grey'>{item.description}</Typography>
          </Grid>
        </Grid>
        <Grid display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <div className={classes.cicoItemProgress}>
            <div className={classes.cicoItemProgressBorder} />
            {changeCICOProgress.isWorking && <CircularProgress variant='indeterminate' size={20} className={classes.cicoItemCircularProgress} />}
            {!changeCICOProgress.isWorking && item.progress !== 100 && <CircularProgress variant='determinate' value={item.progress} size={20} className={classes.cicoItemCircularProgress} />}
            {!changeCICOProgress.isWorking && item.progress === 100 && <img src='/icons/progress-done.svg' alt='progress complete' className={classes.cicoItemCircularProgress} />}
          </div>
          <IconButton>
            <img
               src={`/icons/link-to-bluev2.svg`}
              // src={`/icons/link-to-${item.contentSource === 'fundamentals' ? 'pink' : 'blue'}.svg`}
              alt='link to'
              style={{ ...(item.itemType === 'questionnaire' && { transform: 'rotate(180deg)' }) }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const QuestionnaireModal = ({ open, handleClose, item, userId, changeCICOProgress }) => {
  const classes = useModalStyles()
  const [answers, setAnswers] = useState(item.answers || item.questions?.map(q => ({ ...q, answer: 1 })))

  const onChange = ({ question, answer }) => {
    const newAnswers = answers.map(q => q.title === question.title ? { ...q, answer } : q)
    setAnswers(newAnswers)
  }

  const onSubmit = useCallback(() => {
    changeCICOProgress.trigger({ cicoId: item.pk, userId, stepId: item.stepId, contentId: item.contentId, progress: 100, answers })
    handleClose()
  }, [changeCICOProgress, item, userId, handleClose, answers])

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <div className={classes.modalRoot}>
        <div className={classes.modalTop}>
          <img src='/icons/question.svg' alt='question icon' />
          <IconButton onClick={handleClose}><Close color='primary' /></IconButton>
        </div>
        <DialogContent className={classes.modalContent}>
          {(item.answers || item.questions)?.map((question, idx) => <Grid marginBottom={idx === 0 ? 8 : 0} key={idx}><Question question={question} onChange={onChange} /></Grid>)}
        </DialogContent>
        <Grid display='flex' justifyContent='center'>
          <ReButton variant='contained' action={onSubmit} name='Done' medium />
        </Grid>
      </div>
    </Dialog>
  )
}

const Question = ({ question, onChange }) => {
  const classes = useModalStyles()
  const [sliderValue, setValue] = useState(question.answer || 1)
  const [railtStyle, trackStyle, thumbStyle] = useMemo(() => {
    if (Number.isInteger(parseInt(sliderValue))) {
      return [classes[`sliderRail${sliderValue}`], classes[`sliderTrack${sliderValue}`], classes[`sliderThumb${sliderValue}`]]
    }
    return [classes.sliderRail1, classes.sliderTrack1, classes.sliderThumb1]
  }, [classes, sliderValue])

  return (
    <div>
      <Typography variant='h5' marginBottom={2}>{question.title}</Typography>
      <Slider
        classes={{
          rail: railtStyle,
          track: trackStyle,
          thumb: thumbStyle,
          mark: classes.sliderMark,
          markActive: classes.sliderMark,
          markLabel: classes.sliderMarkLabel,
          markLabelActive: classes.sliderMarkLabelActive,
          disabled: classes.disabled
        }}
        value={sliderValue}
        onChange={(_e, value) => { setValue(value); onChange({ question, answer: value }) }}
        min={1}
        max={6}
        step={1}
        marks={[1, 2, 3, 4, 5, 6].map(m => ({ value: m, label: m }))}
      />
    </div>
  )
}

export { CICOItems }
