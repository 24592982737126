import { ListItemButton, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const OrgMenuItem = ({ name, path }) => {
  const navigate = useNavigate()
  const classes = useStyles()

  const onClick = () => {
    navigate(path);
  }

  return (
    <ListItemButton
      classes={{
        selected: classes.menuItemSelected,
        disabled: classes.menuItemDisabled
      }}
      className={classes.menuItem}
      onClick={onClick}>
      <ListItemText
        primary={
          <Typography className={classes.childNamePrimary}>
            {name}
          </Typography>
        }
      />
    </ListItemButton>
  )
}

export default OrgMenuItem
