import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  boxShadow: {
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    borderRadius: '8px',
    background: '#FFFFFF'
  },
  tableWrapper: {
    padding: theme.spacing(2)
  },
  tableHeader: {
    backgroundColor: '#F6F8FB'
  },
  chipSuccess: {
    backgroundColor: `${theme.palette.success.main}90`,
    color: '#0b6345'
  },
  chipWarning: {
    backgroundColor: `${theme.palette.warning.main}90`,
    color: '#755b19'
  }
}))
