import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const reportTemplate = createSlice({
  name: reducerName,
  initialState: {
    reportKeyList: {}
  },
  reducers: {
    setReportkeyList: (state, { payload }) => {
      if (!payload?.length) return;
      const key = payload[0].PK
      state.reportKeyList[key] = payload
    },
  }
})

export const {
  setReportkeyList
} = reportTemplate.actions

// Reducer
export default reportTemplate.reducer
