import { Grid, Skeleton, Typography } from '@mui/material'
import { progressReducer } from '../../../helpers/utils'
import useCICOContext from '../../../hooks/useCICOContext'
import CICOProgress from '../cicoProgress'
import { useStyles } from '../style'

const CICOSteps = () => {
  const {contents, setSelectedStep, steps } = useCICOContext()
  const classes = useStyles()
  return [...steps].sort((a, b) => a.stepIndex - b.stepIndex).map((step) => {
    const stepContents = contents.filter(c => c.stepId === step.stepId)
    const sum = stepContents.reduce(progressReducer, 0)
    const percent = sum / (stepContents.filter(c => !c.shouldIgnoreOnPercentage).length * 100)
    const stepProgress = Math.round(percent * 100)
    return (
      <Grid item xs={12} sm={6} md={4} key={step.sk}>
        <Grid className={classes.cicoStep} onClick={() => setSelectedStep(step)}>
          <img height={130} src={step.imageName ? `/img/cico/${step.imageName}` : '/img/cico/cico-default-v2.svg'} alt='checkin checkout default' />
          <Grid container justifyContent='space-between' alignItems='flex-end' flexWrap='nowrap' padding={1}>
            <Grid item>
              <Typography variant='h5' color='primary'>Step {step.stepIndex}</Typography>
              <Typography variant='h5' className={classes.cicoStepTitle}>{step.title}</Typography>
              <Typography variant='body1' className={classes.cicoStepDescription}>{step.description}</Typography>
            </Grid>
            <Grid item>
              <CICOProgress loading={false} progress={stepProgress || 0} size='small' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  })
}

const CICOStepsSkeletonList = () => [...Array(6).keys()].map(i => (
  <Grid item xs={12} sm={6} md={4} key={i}>
    <Skeleton width='100%' height={250} variant='rectangular' />
  </Grid>
))

export { CICOSteps, CICOStepsSkeletonList }
