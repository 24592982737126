import { Alert as MuiAlert, AlertTitle, Fade } from '@mui/material'
import React, { createContext, useCallback, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'

const BASE_ALERT_CONFIG = {
  open: false,
  title: '',
  message: '',
  severity: 'success'
}

const ALERT_TYPES = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning'
}

const AlertContext = createContext({
  showAlert: (title, message, severity, durationMs) => {},
  closeAlert: () => {}
})

const useStyles = makeStyles(theme => ({
  alert: {
    position: 'absolute',
    bottom: 10,
    left: 20,
    background: '#FFF',
    minWidth: 400,
    zIndex: 9999
  }
}))

const Alert = ({ open, title, message, severity = ALERT_TYPES.success, onClose }) => {
  const classes = useStyles()

  return (
    <Fade in={open}>
      <MuiAlert severity={severity} onClose={onClose} variant='outlined' className={classes.alert}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </MuiAlert>
    </Fade>
  )
}

let timeout = null

const AlertContextProvider = ({ children }) => {
  const [alertConfig, setAlertConfig] = useState({ ...BASE_ALERT_CONFIG })

  const handleClose = useCallback(() => {
    setAlertConfig({ ...BASE_ALERT_CONFIG })
    clearTimeout(timeout)
  }, [setAlertConfig])

  const handleShowAlert = useCallback((title, message, severity = ALERT_TYPES.success, durationMs = 5000) => {
    setAlertConfig({
      open: true,
      title,
      message,
      severity
    })

    timeout && clearTimeout(timeout)

    timeout = setTimeout(() => {
      handleClose()
    }, durationMs)
  }, [setAlertConfig, handleClose])

  return (
    <AlertContext.Provider value={{ showAlert: handleShowAlert, closeAlert: handleClose }}>
      <Alert {...alertConfig} onClose={handleClose} />
      {children}
    </AlertContext.Provider>
  )
}

export {
  ALERT_TYPES,
  AlertContext,
  AlertContextProvider
}
