import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import useNavigation from '../../../helpers/useNavigation'
import { CustomOverlay } from '../../../components'
import { useAlert } from '../../../hooks'
import OrgForm from '../components/form'
import moment from 'moment'
import Forbidden from '../../403'
import ability from '../../../config/ability'

const OrganisationEditPage = ({ onClick }) => {
  const navigate = useNavigation()
  const { orgId } = useParams()
  const [org, setOrg] = useState()
  const { showSuccessAlert, showErrorAlert } = useAlert()

  const [editOrg] = useCorvusEventList([{
    eventName: 'Organisation Was Edited',
    onSuccess: () => {
      showSuccessAlert('Success!', 'Organisation information was edited')
      navigate('/dashboard/organisations')
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error has occurred')
      console.log(error)
    }
  }])

  const [orgInfoWasRequested] = useCorvusEventList([{
    eventName: 'Organisation Info Was Requested',
    onSuccess: (innerOrg) => {
      const { id, name, demographicFields, hierarchyField, orgAdminEmail, licenses } = innerOrg
      const newOrg = { id, name, orgAdminEmail }
      newOrg.licenses = licenses.map(license => {
        license.startDate = moment(license.startDate)
        license.endDate = moment(license.endDate)
        return license
      })
      const { personalFields, occupationFields } = demographicFields.reduce((acc, i) => {
        const item = {
          aggregatable: i.aggregatable,
          label: i.fieldName,
          type: i.fieldType,
          ...(i.fieldType === 'select' && { options: i.fieldOptions.map(i => ({ id: uuidv4(), value: i.name })) })
        }
        if (i.fieldCategory === 'personal') {
          acc.personalFields.push(item)
        } else {
          acc.occupationFields.push(item)
        }
        return acc;
      }, { personalFields: [], occupationFields: [] })
      newOrg.personalFields = personalFields
      newOrg.occupationFields = occupationFields
      if (hierarchyField && !!Object.keys(hierarchyField).length) {
        newOrg.hierarchyField = {
          // old clients won't have this field, that's why undefined -> true
          enabled: hierarchyField.enabled === undefined ? true : hierarchyField.enabled,
          label: hierarchyField.fieldName,
          options: hierarchyField.fieldOptions.map(i => ({ label: i.name, value: i.key }))
        }
      } else {
        newOrg.hierarchyField = {
          enabled: false, label: '',
          options: [
            { id: uuidv4(), label: 'Role name 1', value: 100 },
            { id: uuidv4(), label: 'Role name 2', value: 200 },
            { id: uuidv4(), label: 'Role name 3', value: 300 }
          ]
        }
      }
      setOrg(newOrg)
    }
  }])

  useEventsOnViewLoad(() => {
    if (orgId) {
      orgInfoWasRequested.trigger({ orgId })
    }
  }, [orgId, orgInfoWasRequested])

  const handleCancelButton = () => {
    navigate('/dashboard/organisations')
  }

  const onSubmit = (org, licenses) => {
    editOrg.trigger({ org, licenses })
  }

  if (!org || orgInfoWasRequested.isWorking) {
    return <CustomOverlay active={orgInfoWasRequested.isWorking} text='Loading organisation information' />
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : <OrgForm
        isEdit
        onSubmit={onSubmit}
        onCancel={handleCancelButton}
        loadingMessage='Editing Organisation Information...'
        loadingView={editOrg.isWorking}
        defaultValues={org}
      />
  )
}
export default OrganisationEditPage
