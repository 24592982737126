import React from 'react'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { selectReportList } from '../../../reducers/reportTemplate/selectors'
import { useStyles } from './style'
import useNavigation from '../../../helpers/useNavigation'

const ReportTemplateList = ({ currentType }) => {
  const currentList = useSelector(state => selectReportList(state, currentType))
  const styles = useStyles()
  const navigate = useNavigation()

  const onClick = (category) => {
    navigate(`/dashboard/manage-reports/edit/${currentType}/${category}`)
  }

  if (!currentList) return <span>Loading...</span>
  return (
    <List className={styles.list}>
      {currentList.map(i => (
        <ListItem
          onClick={() => onClick(i.SK)} className={styles.listItem}
          divider
          secondaryAction={<IconButton edge='end'><Edit /></IconButton>}>
          <ListItemText>{i.label}</ListItemText>
        </ListItem>
      ))}
    </List>
  )

}

export default ReportTemplateList
