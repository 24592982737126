import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Add, ArrowLeft, ArrowRight, Edit, Search } from '@mui/icons-material'
import { useDebounce } from 'use-debounce';
import { Chip, CircularProgress, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { CustomOverlay, ReButton, Empty } from '../../components'
import { useStyles } from './style'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'

const UserManagementHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const searchRef = useRef()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const [localUsers, setLocalUsers] = React.useState([])
  const [cohortList, setCohortList] = useState([])
  const [lastKey, setLastKey] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(-1)
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 1000);

  const [cohortListWasRequested] = useCorvusEventList([{
    eventName: 'All Cohort List Was Requested',
    onSuccess: (result) => {
      setCohortList(result.cohorts)
    }
  }])
  const [usersFromOrgWereSearched] = useCorvusEventList([{
    eventName: 'Users From Org Were Searched',
    onSuccess: (result) => {
      setLocalUsers([result.users])
      setLastKey(result.lastKey)
    }
  }])
  const [nextPageRequested] = useCorvusEventList([{
    eventName: 'Users From Org Were Searched',
    onSuccess: (result) => {
      if (!result.users.length) return;
      setLocalUsers(current => current.concat([result.users]))
      setLastKey(result.lastKey)
      setCurrentPage(current => current + 1)
    }
  }])

  const doSearch = useCallback((v) => {
    setLocalUsers([[]])
    searchRef.current?.blur()
    setCurrentPage(0)
    usersFromOrgWereSearched.trigger({
      tenantKey: selectedView.id,
      attributes: ['clientId', 'ssoId', 'name', 'email', 'cohort'],
      size: 10,
      query: { name: v, email: v }
    })
  }, [usersFromOrgWereSearched, selectedView.id])

  useEventsOnViewLoad(() => {
      cohortListWasRequested.trigger({ tenantKey: selectedView.id })
  }, [cohortListWasRequested, selectedView.id])

  const getNextPage = () => {
    if (localUsers.length <= currentPage + 1) {
      nextPageRequested.trigger({ tenantKey: selectedView.id, attributes: ['clientId', 'ssoId', 'name', 'email', 'cohort'], from: lastKey, size: 10 })
    } else {
      setCurrentPage(current => current + 1)
    }
  }
  const getPreviousPage = () => {
    setCurrentPage(current => current - 1)
  }

  useEffect(() => {
    doSearch(debouncedSearch)
  }, [debouncedSearch, doSearch])

  const isLoading = useMemo(() => usersFromOrgWereSearched.isWorking || nextPageRequested.isWorking,
  [usersFromOrgWereSearched.isWorking, nextPageRequested.isWorking])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h3'>Org. Users</Typography>
        <ReButton name='New group' rounded action={() => navigate('new')} dataTestId='new_user_btn' icon={<Add />} />
      </div>
      <CustomOverlay text='' active={isLoading} spinner={false}>
        <div className={classes.boxShadow}>
          <Box p={4}>
            <Box maxWidth={350} pb={2}>
              <TextField
                inputRef={searchRef}
                fullWidth
                size='small'
                onChange={e => setSearch(e.target.value)}
                inputProps={{ "data-lpignore": true, "autocomplete": 'off' }}
                InputProps={{
                  startAdornment: <InputAdornment>
                    <IconButton onClick={doSearch}><Search /></IconButton>
                  </InputAdornment>
                }}
                placeholder='Search'
              />
            </Box>
            <TableContainer component={Box}>
              <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table' className={classes.tableWrapper}>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>COHORT</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPage !== -1 && localUsers[currentPage].map((row) => (
                    <Row row={row} cohortList={cohortList} />
                  ))}
                </TableBody>
              </Table>
              {((currentPage !== -1 && localUsers[currentPage].length === 0) || !localUsers[0]) && <Empty message='There are no users to display' />}
              <Box display='flex' alignItems='center' justifyContent='center'>
                <IconButton disabled={currentPage === 0 || isLoading} onClick={getPreviousPage}><ArrowLeft /></IconButton>
                {isLoading ? <CircularProgress size={15} color='secondary' /> : <Typography>{currentPage + 1}</Typography>}
                <IconButton disabled={(!lastKey && currentPage + 1 === localUsers.length) || isLoading} onClick={getNextPage}><ArrowRight /></IconButton>
              </Box>
            </TableContainer>
          </Box>
        </div>
      </CustomOverlay>
    </div>
  )
}

const Row = ({ row, cohortList }) => {
  const navigate = useNavigate()
  const cohort = useMemo(() => cohortList?.find(i => i.sk === row.cohort) || {}, [cohortList, row])
  const invite = useMemo(() => cohort.invites?.find(i => i.sk === row.email) || {}, [cohort, row])
  return (
    <TableRow key={row.ssoId}>
      <TableCell scope='row'>{row.name}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{cohort.name}</TableCell>
      <TableCell><StatusChip status={invite.hasBeenUsed} /></TableCell>
      <TableCell>
        <IconButton>
          <Edit onClick={() => navigate(`${encodeURIComponent(row.ssoId)}`)} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const StatusChip = ({ status }) => {

  if (status)
    return (
      <Chip label='Active' sx={{ backgroundColor: '#ECFBF6', color: '#03B575' }} />
    )
  else
    return (
      <Chip label='Inactive' />
    )
}

export default UserManagementHome
