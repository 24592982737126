import React, { useMemo, useState } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Box, Select, TextField, Typography, Skeleton } from '@mui/material'
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker'
import AdapterDateM from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'
import { aggregationConfigs, clientSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import useDashboardContext from '../../hooks/useDashboardContext'

const FilterBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentClientInfo = useSelector(clientSlice.selectors.selectClientInfo)
  const { filters, isOrgProfile, getClient } = useDashboardContext()
  const [localFilters, setLocalFilters] = useState({ date: filters.date, dateHasErrors: filters.dateHasErrors, demographicFields: filters.demographicFields })
  const applyFilters = () => dispatch(aggregationConfigs.actions.setFilters(localFilters))

  const aggregationFields = useMemo(() => {
    const { demographicFields, hierarchyField } = currentClientInfo
    if (getClient.isWorking) return []
    const result = []
    if (demographicFields?.length) {
      result.push(...demographicFields.filter(f => f.aggregatable))
    }
    if (hierarchyField && !!Object.keys(hierarchyField).length) {
      result.push({ ...hierarchyField, fieldKey: 'hierarchyField', fieldType: 'select' })
    }
    return result
  }, [currentClientInfo, getClient.isWorking])

  return (
    <Box display='flex' flexWrap='nowrap' width='100%' justifyContent='flex-start'>
      <Box display='flex' alignItems='center' gap={1} flexWrap='wrap'>
        <Box flex='1 1'>
          <LocalizationProvider dateAdapter={AdapterDateM}>
            <MobileDateRangePicker
              value={[localFilters.date.start, localFilters.date.end]}
              startText=''
              endText=''
              onChange={(date) => setLocalFilters(s => ({ ...s, date: { start: moment(date[0]).startOf('day').format(), end: moment(date[1]).endOf('day').format() } }))}
              onError={(errors) => setLocalFilters(s => ({ ...s, dateHasErrors: errors }))}
              inputFormat='DD/MM/YYYY'
              maxDate={moment()}
              renderInput={(startProps, endProps) => (
                <div className={classes.dateRangePicker}>
                  <Typography component='span'>From:</Typography>
                  <TextField {...startProps} size='small' className={classes.dateRangeTextField} />
                  <Typography component='span'>To:</Typography>
                  <TextField {...endProps} size='small' className={classes.dateRangeTextField} />
                </div>
              )}
            />
          </LocalizationProvider>
        </Box>
        {getClient.isWorking && <Box flex= '4 1'>
          <Skeleton height={40} width='100%' variant='rectangular' />
        </Box>}
        {isOrgProfile &&
          aggregationFields.map(f => (
            <RenderInput
              field={f}
              key={f.fieldKey}
              value={localFilters.demographicFields[f.fieldKey]}
              onChange={(e) => setLocalFilters(s => ({ ...s, demographicFields: { ...s.demographicFields, [f.fieldKey]: e.target.value } }))}
            />
          ))
        }
      </Box>
      <Box ml={2} display='flex' alignItems='center'>
        <Button variant='outlined' onClick={() => applyFilters()} disabled={getClient.isWorking}>
          Apply Filters
        </Button>
      </Box>
    </Box>
  )
}

const RenderInput = ({ field, value, onChange }) => {
  switch (field.fieldType) {
    case 'select': {
      return (
        <Box flex='1 1' minWidth={180}>
          <FormControl size='small' style={{ width: '100%' }}>
            <InputLabel style={{ top: '-4px' }}>{field.fieldName}</InputLabel>
            <Select
              value={value || ''}
              onChange={onChange}
              size='small'
              variant='outlined'
              label={field.fieldName}
              style={{height: '36px'}}
            >
              <MenuItem value={null}>None</MenuItem>
              {field.fieldOptions.map(({ key, name }) => (
                <MenuItem key={key} value={key}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    case 'input': {
      return (
        <Box flex='1 1' minWidth={180}>
          <FormControl size='small' style={{ width: '100%' }}>
            <TextField
              value={value || ''}
              onChange={onChange}
              size='small'
              variant='outlined'
              label={field.fieldName}
              inputProps={{ style: { height: '19px' } }}
              InputLabelProps={{ style: { top: '-4px' } }}
            />
          </FormControl>
        </Box>
      )
    }

    default:
      return null
  }
}

export default FilterBar
