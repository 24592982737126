import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReButton } from '../../../../components';

const SelectOptionModal = ({ open, setOpen, baseName, index }) => {
  const {
    control,
    watch,
  } = useFormContext([]);

  const { append, remove } =
    useFieldArray({
      control,
      name: `${baseName}.${index}.options`,
    });
  const getInfo = watch(`${baseName}.${index}`);

  const hasMoreThanOneRecord = useMemo(
    () => getInfo.options?.length > 1,
    [getInfo.options]
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{getInfo.label} options</DialogTitle>
      <DialogContent>
        <Box width={400}>
          <Box mt={2} display='flex' flexDirection='column' gap={2}>
            {
              getInfo.options?.map((_item, innerIdx) => (
                <Box display='flex' key={_item.id}>
                  <Controller
                    name={`${baseName}.${index}.options.${innerIdx}.value`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Field type"
                        placeholder="Field type"
                      />
                    )}
                  />
                  {hasMoreThanOneRecord && (
                    <Box mt={1}>
                      <IconButton
                        sx={{ color: "#CCCCCC" }}
                        onClick={() => remove(innerIdx)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))
            }
          </Box>
          <Box mb={3} mt={3}>
            <IconButton color="secondary" onClick={() => append({ id: uuidv4(), value: '' })}>
              <AddCircle fontSize="small" />
              <Typography fontSize={14} fontWeight="bold">
                Add another option
              </Typography>
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <ReButton name="Save" action={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  )
}

export { SelectOptionModal };