import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReButton } from '../../../../components';

const HierarchyFieldModal = ({ open, setOpen, baseName }) => {
  const {
    control,
    watch
  } = useFormContext([]);
  const getInfo = watch(baseName);

  const { append, remove } =
    useFieldArray({
      control,
      name: `${baseName}.options`,
    });

  const hasMoreThanOneRecord = useMemo(
    () => getInfo.options?.length > 1,
    [getInfo]
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Hierarchy options</DialogTitle>
      <DialogContent>
        <Box width={400}>
          <Box mt={2} display='flex' flexDirection='column' gap={2}>
            {
              getInfo.options?.map((_item, innerIdx) => (
                <Box key={_item.id} display='flex'>
                  <Controller
                    name={`${baseName}.options.${innerIdx}.label`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Field label"
                        placeholder="Field label"
                      />
                    )}
                  />
                  <Controller
                    name={`${baseName}.options.${innerIdx}.value`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type='number'
                        label="Field value"
                        placeholder="Field value"
                      />
                    )}
                  />
                  {hasMoreThanOneRecord && (
                    <Box mt={1}>
                      <IconButton
                        sx={{ color: "#CCCCCC" }}
                        onClick={() => remove(innerIdx)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))
            }
          </Box>
          <Box mb={3} mt={3}>
            <IconButton color="secondary" onClick={() => append({ id: uuidv4(), label: '', value: 0 })}>
              <AddCircle fontSize="small" />
              <Typography fontSize={14} fontWeight="bold">
                Add another option
              </Typography>
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <ReButton name="Save" action={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  )
}

export { HierarchyFieldModal };