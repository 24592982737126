import { Grid } from '@mui/material'
import React from 'react'
import { InformationalCard, ScoreBadge, UsersBadge, ReButton } from '../../components'
import { resultDescriptions, resultLabels } from '../../helpers/copy'
import { QuestionnaireOrIndicatorText } from '../../pages/resilience-evaluation'
import { initialOptions } from '../questionnaire-indicator-card/charts'
import TipsSmallCarousel from '../tips-small-carousel'
import { useStyles } from './style'

const DashboardResults = ({ title, type, totalUsers, results, isOrgProfile, labels, accState, ...props }) => {
  const classes = useStyles()
  const contentStyle = props?.vertical ? classes.cardsContainerVertical : classes.cardsContainer

  if (!labels) {
    labels = isOrgProfile ? resultLabels.orgView : resultLabels.userView
  }

  const shouldHideResults = !isOrgProfile && ['breakdown', 'fragmentation'].includes(results.levelKey)

  return (
    <Grid className={classes.root}>
      <Grid item xs={isOrgProfile || type === 'indicator' ? 12 : 9}>
        <InformationalCard title={title} accState={accState}>
          {!shouldHideResults ? (
            <div>
              {isOrgProfile && (
                <div style={{ display: 'flex' }}>
                  <UsersBadge totalUsers={totalUsers} />
                </div>
              )}
              <div className={contentStyle}>
                <ScoreBadge title={labels.score} description={resultDescriptions.score} vertical={props?.vertical}>
                  <b> {results && results.score ? results.score : 0} </b> out of 6
                </ScoreBadge>
                <ScoreBadge title={labels.level} description={resultDescriptions.level} vertical={props?.vertical}>
                  <b> {results && results.level} </b>
                </ScoreBadge>
                <ScoreBadge title={labels.range} description={resultDescriptions.range} vertical={props?.vertical}>
                  <b> {results && `${results.averageLowScore} - ${results.averageHighScore}`} </b>
                </ScoreBadge>
                <ScoreBadge title={labels.variability} description={resultDescriptions.variability} vertical={props?.vertical}>
                  <b className={classes.text}> {results && results.averageVariance} </b>
                </ScoreBadge>
                {isOrgProfile && (
                  <ScoreBadge title={labels.mostFrequent} description={resultDescriptions.mostFrequentResult} vertical={props?.vertical}>
                    <b className={classes.text}> {initialOptions.find(i => i.key === results?.mostFrequentResult?.key)?.name || ''} </b>
                  </ScoreBadge>)}
              </div>
            </div>)
            : (
              <InformationalCard title={title} accState={accState}>
                <QuestionnaireOrIndicatorText type={type} unauthenticated={true} />
              </InformationalCard>
            )}
          {!isOrgProfile && (
            <div className={classes.btnContainer}>
              <ReButton name={type === 'indicator' ? 'Retake Indicator' : 'Retake Questionnaire'} action={props?.startHandler} dataTestId='start_now_btn_sec' />
            </div>
          )}
        </InformationalCard>
      </Grid>
      {(!props.vertical && !isOrgProfile && type === 'resilience') &&
        <Grid xs={3}>
          <TipsSmallCarousel />
        </Grid>}
    </Grid>
  )
}

export default DashboardResults
