import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { useCorvusEventList } from "@emerald-works/react-event-bus-client";
import useNavigation from "../../../helpers/useNavigation";
import { useAlert } from "../../../hooks";
import OrgForm from "../components/form";
import { clientSlice } from "../../../reducers";
import { useDispatch } from "react-redux";
import Forbidden from "../../403";
import ability from "../../../config/ability";

const OrganisationCreatePage = () => {
  const navigate = useNavigation();
  const { showSuccessAlert } = useAlert();
  const dispatch = useDispatch();

  const [createOrg] = useCorvusEventList([clientSlice.eventBus.createOrg]);
  const handleCancelButton = () => {
    navigate("/dashboard/organisations");
  };

  const onSubmit = (org, licenses) => {
    createOrg.trigger({ org, licenses });
    showSuccessAlert(
      "Success!",
      "The Organisation will be created in the next couple of minutes"
    );
    dispatch(clientSlice.actions.addLoadingToList());
    navigate("/dashboard/organisations", {
      state: { disableInitialFetch: true },
    });
  };

  const isSuperAdmin = ability.can("view", "superAdminView");

  return !isSuperAdmin ? (
    <Forbidden />
  ) : (
    <OrgForm
      onSubmit={onSubmit}
      onCancel={handleCancelButton}
      loadingMessage="Creating Organisation..."
      loadingView={createOrg.isWorking}
      defaultValues={{
        id: "",
        name: "",
        orgAdminEmail: [
          {
            adminEmail: "",
          },
        ],
        personalFields: [{ id: uuidv4(), label: "", type: "input", aggregatable: true, options: [{ id: uuidv4(), value: '' }] }],
        occupationFields: [{ id: uuidv4(), label: "", type: "input", aggregatable: true, options: [{ id: uuidv4(), value: '' }] }],
        hierarchyField: {
          enabled: false,
          label: '',
          options: [
            { id: uuidv4(), label: 'Role name 1', value: 100 },
            { id: uuidv4(), label: 'Role name 2', value: 200 },
            { id: uuidv4(), label: 'Role name 3', value: 300 }
          ]
        },
        licenses: [],
      }}
    />
  );
};
export default OrganisationCreatePage;
