import React, { useEffect, useMemo } from 'react'
import { SurveyTimeline, SurveyQuestion, ReButton, DriverQuestion, ResilienceSurveyResult, Loading } from '../../components'
import { Link, Typography, Button } from '@mui/material'
import { surveySlice, userSlice } from '../../reducers'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLocation, useNavigate } from 'react-router-dom'
import useResolvePathFromBaseURL from '../../helpers/useResolvePathFromBaseURL'
import { useStyles } from './style'
import { useEventContext } from '../../contexts/event'
import { useOnboarding } from '../../hooks'
import { featureSelector } from '../../reducers/user/selectors'
import { Forbidden } from '../../pages'

const ReSurvey = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { next } = useOnboarding()

  const [getStepsBurst] = useCorvusEventList([surveySlice.eventBus.getSteps])
  const [calculateBurst] = useCorvusEventList([surveySlice.eventBus.calculate])
  const { getUserQuestionnairePathway } = useEventContext()
  const { getUser } = useEventContext()

  const { pathname: dashboardPath } = useResolvePathFromBaseURL('dashboard')
  const user = useSelector(userSlice.selectors.selectUser)
  const result = useSelector(surveySlice.selectors.selectResult)
  const steps = useSelector(surveySlice.selectors.selectSteps)
  const stepsLength = useMemo(() => steps.length - 2, [steps])

  const responses = useSelector(surveySlice.selectors.selectResponses)

  useEventsOnViewLoad(() => {
    getStepsBurst.trigger()
    calculateBurst.registerAdhocOnSuccessListener(() => {
      getUserQuestionnairePathway.trigger()
    })
  }, [getStepsBurst, calculateBurst, getUserQuestionnairePathway, dispatch])

  const [initialStep, setInitialStep] = React.useState(user?.lastStepAnswered?.step || 0)
  const [step, setStep] = React.useState(initialStep)
  const [stepFinished, setStepFinished] = React.useState(false)
  const features = useSelector(featureSelector)

  const goToPrevious = () => {
    setStep(step - 1)
    window.scrollTo(0, 90)
  }

  const goToNext = () => {
    setStep(step + 1)
    window.scrollTo(0, 90)
  }

  const goToScores = () => {
    calculateBurst.trigger()
    goToNext()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let pageQuestions = []
  let pageDescription
  let pageTitle
  let pageSubtitle

  if (steps && steps.length) {
    pageTitle = steps[step].title
    pageSubtitle = steps[step].subtitle
    pageDescription = steps[step].description
    pageQuestions = steps[step].questions
  }

  if (pageQuestions.length && initialStep) {
    const stepLastQuestion = pageQuestions[pageQuestions.length - 1]
    const lastAnsweredQuestion = user.lastStepAnswered.question
    if (stepLastQuestion.position === lastAnsweredQuestion && step < stepsLength) {
      goToNext()
    }
    setInitialStep(null)
  }

  const handleReturn = (hasFinished) => {
    if (hasFinished && location.state?.fromOnBoarding) {
      next()
    }
    navigate(dashboardPath)
  }

  useEffect(() => {
    const finished = pageQuestions.map(question => {
      const stepResponses = responses.filter(response => response.question.step === question.step)
      return stepResponses.findIndex(response => response.question.position === question.position)
    }).indexOf(-1) < 0
    setStepFinished(finished)
  }, [pageQuestions, stepFinished, responses, user])

  return (
    !features.RESILIENCE_EVALUATION && !getUser.isWorking ? <Forbidden />
      : !getUser.isWorking
        ? <div className={classes.root}>
          <Button className={classes.button} onClick={handleReturn} startIcon={<ArrowBackIcon />}>Return to dashboard</Button>
          {!steps.length ? (<Loading />) : (
            <span>
              <Typography className={classes.title} variant='h4' color='secondary'>{pageTitle}</Typography>
              <Typography className={classes.description}>{pageSubtitle}</Typography>
              <SurveyTimeline steps={steps} step={step} />
              {steps && steps[step].id !== 'complete' && (
                <div dangerouslySetInnerHTML={{ __html: pageDescription }} /> // TODO: whitelist html tags
              )}
              {pageQuestions.map(question => {
                const answers = responses
                const answer = answers
                  ? answers.find(ans => ans.question.type === question.type &&
                    ans.question.step === question.step &&
                    ans.question.position === question.position &&
                    parseInt(ans.cycle) === parseInt(user?.currentCycle || 1))
                  : undefined

                if (question.type === 'evaluation') {
                  return (
                    <SurveyQuestion key={question.id} question={question} answer={answer} />
                  )
                } else if (question.type === 'driver') {
                  return (
                    <DriverQuestion key={question.id} question={question} answer={answer} />
                  )
                }
                return null
              })}
              {calculateBurst.isWorking && (<Loading message='Calculating Results...' />)}
              {!calculateBurst.isWorking && steps && steps[step].id !== 'complete' && (
                <div className={classes.footer}>
                  <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'><Link onClick={handleReturn}>Skip for now</Link> and finish later</Typography>
                  {steps && steps.length && steps[step].id !== 'initial' && (
                    <Button className={classes.previousButton} color='secondary' variant='outlined' data-test='previous_section_btn' onClick={goToPrevious}>Previous section</Button>
                  )}
                  {step < stepsLength && <ReButton className={classes.footerButton} name='Next Section' dataTestId='next-section-btn' disabled={!stepFinished} action={goToNext} />}
                  {step === stepsLength && <ReButton className={classes.footerButton} name='Calculate score' dataTestId='calc-score-btn' disabled={!stepFinished} action={goToScores} />}
                </div>
              )}
              {!calculateBurst.isWorking && steps && steps[step].id === 'complete' && result !== null && (
                <>
                  <ResilienceSurveyResult
                    score={result.score}
                    level={result.modelResult.level}
                    high={result.averageHighScore}
                    low={result.averageLowScore}
                    variance={result.averageVariance}
                    levelKey={result.modelResult.levelKey}
                    pdfLink={result.pdf}
                  />
                  <ReButton className={classes.footerButton} name='Finish Questionnaire' dataTestId='finish_questionnaire_btn' action={() => handleReturn(true)} />
                </>
              )}
            </span>
          )}
        </div> : <>Loading...</>
  )
}

export default ReSurvey
