import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  main: {
    background: '#FFFFFF',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    borderRadius: '8px',
    padding: theme.spacing(4, 3),
    marginTop: theme.spacing(4)
  }
}))
