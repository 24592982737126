import { useState } from "react";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { ReButton } from "../../../../components"
import { useStyles } from "./styles";
import { HierarchyFieldModal } from "./hierarchy-field-modal";

export const HierarchyField = () => {
  const classes = useStyles();
  const { control, watch } = useFormContext([]);
  const info = watch('hierarchyField')
  const [open, setOpen] = useState();

  return (
    <Box display='flex' flexDirection='column'>
      <Controller
        name={'hierarchyField.enabled'}
        control={control}
        render={({ field }) => (
          <FormControlLabel control={<Checkbox defaultChecked />} label="Enabled" {...field} checked={field.value} />
        )}
      />
      {info.enabled && <Box display='flex' width={600} gap={2}>
        <HierarchyFieldModal baseName='hierarchyField' open={open} setOpen={setOpen} />
        <Controller
          name={"hierarchyField.label"}
          control={control}
          render={({ field }) => (
            <TextField
              className={classes.input}
              {...field}
              label="Hierarchy Field label"
              placeholder="Hierarchy Field label"
            />
          )}
        />
        <Box display="flex" width={145}>
          <ReButton
            action={() => setOpen(true)}
            name="Add options"
            bgColor="#FFF"
            color="#465270"
            btBorderWidth="2px"
            btBorderColor="#465270"
          />
        </Box>
      </Box>}
    </Box>
  )
}