import React, { useMemo, useState } from 'react'
import { useStyles } from '../form/styles'
import { Box, Paper, Typography, LinearProgress } from '@mui/material'
import { CustomOverlay, ReButton } from '../../../../components'
import BasicInfo from './general-info'
import PlanSelection from './plan-selection'
import { useForm, FormProvider } from 'react-hook-form'
import ReviewFormData from './review'
import OrgSpecifics from './org-specifics'
import { useAlert } from '../../../../hooks'

export const PAGE_STEPS = {
  basic_info: 0,
  plan_selection: 1,
  org_specifics: 2,
  review_form_data: 3
}

const OrgForm = ({ onClick, defaultValues, onSubmit, loadingView, loadingMessage, onCancel, isEdit }) => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const { showErrorAlert } = useAlert()

  const methods = useForm({
    mode: 'all',
    defaultValues
  })

  const formTitles = useMemo(() => {
    if (isEdit) return ['Edit Organisation', 'Edit Organisation', 'Organization specifics', 'Review']
    return ['New Organisation', 'New Organisation', 'Organization specifics', 'Review']
  }, [isEdit])

  const goToPage = (step) => {
    setStep(step)
  }

  const manageSubmitButton = onClick = async data => {
    const validateFieldsAndProceed = async (fields) => {
      const result = await methods.trigger(fields)
      if (result) setStep((currStep) => currStep + 1)
    }

    switch (step) {
      case PAGE_STEPS.review_form_data: {
        const { licenses, ...submitData } = methods.getValues()
        if (!submitData.hierarchyField.enabled) delete submitData.hierarchyField
        const removeUnlessSelect = (i) => { if (i.type !== 'select') delete i.options }
        submitData.personalFields.forEach(i => removeUnlessSelect(i))
        submitData.occupationFields.forEach(i => removeUnlessSelect(i))
        onSubmit(submitData, licenses)
        return;
      }
      case PAGE_STEPS.basic_info: {
        await validateFieldsAndProceed(['name', 'orgAdminEmail'])
        return;
      }
      case PAGE_STEPS.plan_selection: {
        await validateFieldsAndProceed(['licenses'])
        return;
      }
      case PAGE_STEPS.org_specifics: {
        const { licenses, ...submitData } = methods.getValues()
        const { personalFields, occupationFields, hierarchyField } = submitData
        let errorInfo = false;
        const checkFields = (i) => {
          if (i.label === undefined || i.label.length === 0) {
            errorInfo = { message: 'Missing label' };
          }
          if (i.type === 'select') {
            if (!i.options.length) errorInfo = { message: `Select ${i.label} field is missing options` };
            i.options.forEach(opt => {
              if (!opt.value.length) {
                errorInfo = { message: `Select ${i.label} field has invalid option` }
              }
            })
          }
        }
        personalFields.forEach(checkFields)
        occupationFields.forEach(checkFields)
        if (hierarchyField.enabled) {
          if (hierarchyField.options.some(opt => !opt.label.length))
            errorInfo = { message: 'Hierarchy field is missing options' };
          if (!hierarchyField.label.length)
            errorInfo = { message: 'Hierarchy field has no label' };
        }
        if (errorInfo) showErrorAlert("Error", errorInfo.message)
        else setStep(s => s + 1)
        return
      }

      default: return;
    }
  }

  const managePreviousButton = onClick = data => {
    setStep((currStep) => currStep - 1)
  }

  const stepSelector = useMemo(() => {
    switch (step) {
      case 0: return <BasicInfo isEdit={isEdit} />
      case 1: return <PlanSelection />
      case 2: return <OrgSpecifics />
      case 3: return <ReviewFormData goToPage={goToPage} />
      default: return <div></div>
    }
  }, [step, isEdit])

  return (
    <FormProvider {...methods}>
      <form>
        <CustomOverlay
          active={loadingView}
          text={loadingMessage || 'loading...'}
        >
          <div className='form'>
            <Paper className={classes.container}>
              <div className='progress-bar'>
                <LinearProgress
                  variant='determinate'
                  value={(step + 1) * 100 / Object.keys(PAGE_STEPS).length}
                />
                <Typography color='secondary' fontSize={14}>
                  Step {step + 1} of 4
                </Typography>
              </div>
              <div>
                <Typography variant='h4' className={classes.header}>
                  {formTitles[step]}
                </Typography>
              </div>
              <div>
                {stepSelector}
              </div>
              <Box display='flex' flexDirection='row' mt={3.8}>
                <Box>
                  <ReButton
                    name='Cancel'
                    type='button'
                    bgColor='#FFF'
                    color='#465270'
                    btBorderWidth='2px'
                    btBorderColor='#303155'
                    action={onCancel}
                  />
                </Box>
                <Box ml={2.4}>
                  <ReButton
                    name='Previous'
                    type='button'
                    disabled={step === PAGE_STEPS.basic_info}
                    action={managePreviousButton}
                  />
                </Box>
                <Box ml={2.4}>
                  <ReButton
                    // disabled={Object.keys(methods.formState.errors).length}
                    name={step === PAGE_STEPS.review_form_data ? 'Finish' : 'Next'}
                    type='button'
                    action={manageSubmitButton}
                  />
                </Box>
              </Box>
            </Paper>
          </div>
        </CustomOverlay>
      </form>
    </FormProvider>
  )
}

export default OrgForm
