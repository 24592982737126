import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  list: {
  },
  listItem: {
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F6F8FB',
      transition: '0.3s ease-in-out'
    }
  }
}))
