import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useEventContext } from '../contexts/event'
import { progressReducer } from '../helpers/utils'
import { cicoSlice } from '../reducers'

const CICOContext = createContext({})

const useCICOContext = () => {
  return useContext(CICOContext)
}

const CICOContextProvider = ({ children }) => {
  const events = useEventContext()
  const [getCICOInfo] = useCorvusEventList([cicoSlice.eventBus.getInfo])
  const [CICOProgressWasUpdated] = useCorvusEventList([cicoSlice.eventBus.cicoProgressWasUpdated])
  const steps = useSelector(cicoSlice.selectors.selectSteps)
  const contents = useSelector(cicoSlice.selectors.selectContents)
  const info = useSelector(cicoSlice.selectors.selectInfo)
  const [cicoSubType, setCicoSubType] = useState('fast') // current subtypes are fast, depth, and caseStudy
  const [selectedStep, setSelectedStep] = useState(null)
  const [selectedItem, setSelectedItem] = useState(-1)

  const currentStepIndex = useMemo(() => {
    if (!selectedStep?.stepIndex) return null
    const current = steps.findIndex(s => s.stepIndex === selectedStep.stepIndex)
    return current
  }, [selectedStep?.stepIndex, steps])

  const loading = useMemo(() => getCICOInfo.isWorking || events.getUser.isWorking, [getCICOInfo.isWorking, events.getUser.isWorking])
  const allProgress = useMemo(() => {
    const contentsCopy = [...contents]
    const total = contentsCopy.reduce(progressReducer, 0)
    const percent = total / (contentsCopy.filter(c => !c.shouldIgnoreOnPercentage).length * 100)
    return Math.round(percent * 100)
  }, [contents])

  const moveNextStep = useCallback(() => {
    setSelectedStep(steps[currentStepIndex + 1])
  }, [steps, currentStepIndex])

  const moveNextItem = useCallback(() => {
    setSelectedItem(s => s + 1)
  }, [])

  const items = useMemo(() => {
    switch (cicoSubType) {
      case 'fast':
        return [...contents].filter(i => i.fastTrackOrder !== 0).sort((a, b) => a.fastTrackOrder - b.fastTrackOrder)
      case 'caseStudy':
        return [...contents].filter(i => i.caseStudyOrder !== 0).sort((a, b) => a.caseStudyOrder - b.caseStudyOrder)
      case 'depth':
        return [...contents].filter(item => item.stepId === selectedStep?.stepId).sort((a, b) => a.contentIndex - b.contentIndex)
      default:
        return []
    }
  }, [contents, cicoSubType, selectedStep?.stepId])

  const isLastStep = useMemo(() => {
    if (cicoSubType !== 'depth') return true
    return currentStepIndex === steps.length - 1
  }, [cicoSubType, currentStepIndex, steps.length])

  return (
    <CICOContext.Provider
      value={{
        getCICOInfo,
        CICOProgressWasUpdated,
        steps,
        contents,
        info,
        cicoSubType,
        setCicoSubType,
        selectedStep,
        setSelectedStep,
        selectedItem,
        setSelectedItem,
        moveNextStep,
        moveNextItem,
        loading,
        currentStepIndex,
        allProgress,
        items,
        isLastStep
      }}
    >
      {children}
    </CICOContext.Provider>)
}

export { CICOContextProvider }
export default useCICOContext
